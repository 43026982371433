<template>
    <div class="page">
        <!--顶部标题-->
        <div class="top-title">
            <div style="float: left; width: 20%">
                <div style="margin-left: 16px; margin-top: 11px">
                    <span class="topspan">{{leftTitle}}</span>
                </div>
            </div>
            <div style="width: 60%; float: left; text-align: center">
                <div style="margin-top: 9px">
                    <span class="text-gradient">{{centerTitle}}</span>
                </div>
            </div>

            <div style="float: right; width: 20%">
                <div style="margin-top: 13px;">
                    <div class="secondspan" v-if="this.type == 0">
                        <img src="../../../assets/img/fullscreen.png" @click="magnify()"/>
                    </div>
                </div>
            </div>
        </div>


        <!--数据概览统计-->
        <div class="top-box">
            <div class="top-text-box">
                <div style="padding-top: 15px;">
                    <div style="color: #00CFF3;font-size: 18px;text-align: center;font-weight: bold;">用户总数</div>
                    <div style="color: #FFFFFF;font-size: 16px;margin-top: 20px;text-align: center;">{{userNumber}}</div>
                </div>
            </div>

            <div class="top-text-box">
                <div style="padding-top: 15px;">
                    <div style="color: #00CFF3;font-size: 18px;text-align: center;font-weight: bold;">健康报告</div>
                    <div style="color: #FFFFFF;font-size: 16px;margin-top: 20px;text-align: center;">999</div>
                </div>
            </div>

            <div class="top-text-box">
                <div style="padding-top: 15px;">
                    <div style="color: #00CFF3;font-size: 18px;text-align: center;font-weight: bold;">推送记录</div>
                    <div style="color: #FFFFFF;font-size: 16px;margin-top: 20px;text-align: center;">560</div>
                </div>
            </div>

        </div>

        <!--数据模块-->
        <div class="data-box">
            <div style="width:50%;height: 100%;margin-left: 10px;">
                 <!--推送记录曲线图模块-->
                 <div class="data-box1">
                    <div class="data-right-box">
                        <div style="color:#FFFFFF;font-size:18px;position: relative;top:-3px;left:10px;float: left;">推送记录</div>
                        <div id="pushRecordType" class="pie-style"></div>
                    </div>
                </div>

                 <!--推送类型模块-->
                 <div class="data-box1">
                    <div class="data-right-box">
                        <div style="color:#FFFFFF;font-size:18px;position: relative;top:-3px;left:10px;float: left;">推送类型比例</div>
                        <div id="pushType" class="pie-style"></div>
                    </div>
                </div>
            </div>
            <div style="width:50%;height: 100%;">
                <div class="data-table-box">
                    <!-- <div style="color:#FFFFFF;font-size:18px;position: relative;top:-3px;left:10px;float: left;">推送类型比例</div> -->
                    <el-table :data="tableData" :border="false" style="width: 100%;height: 100%;" :row-style="rowStyle" :header-cell-style="{ background: '#285df0'}" :row-class-name="tableRowClassName" empty-text="暂无数据">
                        <el-table-column prop="time" label="日期" width="120" label-class-name="tablehead" align="center " />
                        <el-table-column prop="serviceUnit" width="120" label="服务机构" label-class-name="tablehead" align="center " />
                        <el-table-column prop="serviceType" label="服务类别" width="80" label-class-name="tablehead" align="center " />
                        <el-table-column prop="content" label="内容" label-class-name="tablehead" align="center " />
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='./js/operate-report.js'></script>
<style scoped src="./css/operate-report.css"></style>

<style>
.tablehead {
    color: white;
  }
  .data-table-box .el-table__row>td{
	border: none;
}
  .data-table-box .el-table::before {
	height: 0px;
  }
 .data-table-box .el-table{
    background-color: #000c3c;
  }
  .el-table .report-row{
    background-color: #000c3c;
  }
  .data-table-box .el-table--enable-row-hover .el-table__body tr:hover > td {
   background-color: #000c3c;
}

</style>