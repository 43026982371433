/**
 * 主设备
 */
 import * as echarts from 'echarts'
 import pushRecordJs from "./operate-push-record";
 import pushTypeJs from "./operate-push-type";
 import session from "../../../../store/store";


export default {

    data() {
        return {
           userNumber:5645,
           type:0, //1:全屏
           leftTitle:'',
           centerTitle:'',
           tableData: [
            {serviceUnit:'雅职院体检科',serviceType:'健康管理',content:'赵坤玉,370682198211185613,13612950824,高血压,高血脂',time:'2022-08-05'},
            {serviceUnit:'德品医疗',serviceType:'临床康复',content:'张国芳,51052419890403038X,18824669681,低血糖,能力完好',time:'2022-08-04'},
            {serviceUnit:'威宁养老康复实训室',serviceType:'医养服务',content:'赵建国,422431194506294021,15919412865,高血糖,高血压,轻度失能',time:'2022-07-19'},
            {serviceUnit:'文汇社区服务中心',serviceType:'健康管理',content:'张朝福,43032119590317172X,18901536220,高血脂,轻度失能',time:'2022-07-15'},
            {serviceUnit:'雅职院体检科',serviceType:'健康管理',content:'李海韬,630105198502231310,17005811688,高血压,高血糖,高尿酸',time:'2022-07-14'}
           ],
        };
    },
    mounted() {
      this.init();
      if(this.$route.query.type != null) {
        this.type = this.$route.query.type;
        document.title = session.getSysName() + '-' + '大数据中心'
      }

      if(0 == this.type) {
        this.leftTitle = '';
        this.centerTitle = '运 营 报 表';
      }else if(1 == this.type) {
        this.leftTitle = '运营报表'; 
        this.centerTitle = '大 数 据 中 心';
      }
    },
    methods: {
      init() {
        pushTypeJs.setPushTypeChart(echarts);
        pushRecordJs.setPushRecordChart(echarts);
      },
      rowStyle() {
        return 'color:white'; 
      },
      tableRowClassName() {
        return 'report-row'; 
      },
      magnify() {
        const routeData = this.$router.resolve({
            path: "/operate-report-full-screen",
            query: {
                type: 1     //全屏
             } 
          })
        window.open(routeData.href);
      }
    }
};