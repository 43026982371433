/**
 * 设备类型
 */

// import * as echarts from 'echarts'

export default {
  setOption(echarts, option) {
        const doc = document.getElementById("pushRecordType")
        let myChart = echarts.getInstanceByDom(doc);
        if (myChart == undefined) {
            myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
        }
        myChart.clear(); // 清除画布
        doc.setAttribute('_echarts_instance_', '')
        myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
        window.onresize = myChart.resize
    },

    setPushRecordChart(echarts) {
      var option = {
        tooltip: {
            trigger: 'axis'
          },
          color: ['#93adf7'],
          grid: {
            left: '1%',
            right: '1%',
            top: '12%',
            bottom: '1',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['0', '5', '10', '15', '20', '25', '30'],
              axisLine: {
                lineStyle: {           
                    color: '#fff'
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              splitLine:{
                show: false
              },
              axisLine: {
                lineStyle: {           
                    color: '#fff'
                }
              }
            }
          ],
          series: [
            {
              name: 'Line 1',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 2,
                color: '#9383F6'
              },
              showSymbol: true,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#0CB6FF'
                  },
                  {
                    offset: 1,
                    color: '#6ADCF5'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: [140, 232, 101, 264, 90, 340, 250]
            }
          ]
    };

    this.setOption(echarts,option);
    },
};